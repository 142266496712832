import React, { useEffect } from 'react';
import SiteHeader from 'components/modules/SiteHeader';
import MobileAppHero from 'components/mobile-app/MobileAppHero/MobileAppHero';
import styles from 'components/mobile-app/MobileAppHero/mobileAppHero.module.scss';
import FeatureSection from 'components/mobile-app/FeatureSection/FeatureSection';
import { sections, metaData } from 'components/mobile-app/data/appPageData';
import AppLinkSection from 'components/mobile-app/AppLinkSection/AppLinkSection';
import TestimonialCarousel from 'components/mobile-app/TestimonialCarousel/TestimonialCarousel';
import AnalyticsService from 'lib/analytics/mixpanel/mixpanel-browser';
import events from 'lib/analytics/mixpanel/events';
import parseUserV2 from 'utils/parseUserV2';

export type MobileAppLandingPageProps = {
  pathname: string;
  domainSiteKey: string;
  queryParams?: Record<string, any>;
  url: string;
  userToken?: string;
};
const MobileApps = (props: MobileAppLandingPageProps) => {
  const { domainSiteKey, pathname, queryParams, url, userToken } = props;

  useEffect(() => {
    const user = parseUserV2(userToken);
    if (userToken) {
      AnalyticsService.setUser(user);
    }
    AnalyticsService.track(events.PAGEVIEW, {
      location: 'Mobile App Landing Page',
      pathname: pathname || '/mobile-app',
    });
  }, [pathname, userToken]);

  return (
    <>
      <SiteHeader
        canonical={url}
        meta={metaData}
        pathname={pathname}
        queryParams={queryParams}
        reducedTracking={false}
        siteKey={domainSiteKey}
        url={url}
      />
      <div className={styles.mainPageWrapper}>
        <MobileAppHero />
        {sections.map((section, index) => (
          <FeatureSection
            key={`section-${index}`}
            section={section}
            isEven={index % 2 == 0}
          />
        ))}
        <TestimonialCarousel />
        <AppLinkSection />
      </div>
    </>
  );
};

export default MobileApps;
